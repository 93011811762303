@import '../variables';

.Layout,
.LayoutColor {
    min-height: 90rem;
    max-width: 160rem;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: 10rem 1fr min-content;
    grid-template-areas: "header header"
                         "nav cont"
                         "footer footer";
    box-shadow: $shadow-medium;
    margin: 0 auto;

    @media only screen and (max-width: $screen-small) {
        grid-template-areas: "header header"
                            "nav nav"                            
                            "cont cont"
                            "footer footer";
        grid-template-rows: 10rem min-content 1fr min-content;
        margin: 0 auto;
    }
}

.LayoutMargin {
    margin: 5rem auto;
}

.Layout {
    animation: fadeIn .8s;
}
