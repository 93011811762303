@import '../../variables';

.ButtonPrimary {
    outline: none;
    text-transform: uppercase;
    border: none;
    width: 6rem;
    height: 6rem;
    background-color: $color-tertiary;
    color: $color-tertiary;
    
}

.LandingPage {
    border-radius: 50%;
    outline: none;
    animation: moveInBottom .5s ease-out .75s;
    animation-fill-mode: backwards;
    color: $color-tertiary;
    background-color: $color-tertiary;
    z-index: 200;
    grid-row: 1 / 2;
    grid-column: 1/ 2;
    &::after {
        background-color: $color-tertiary;
    }

    &,
    &:link,
    &:visited {
        outline: none;
        display: inline-block;
        transition: all .2s;
        position: relative;
        font-size: $font-primary-size;
        border: none;
        cursor: pointer;
    }


    &:hover {
        transform: translateY(-3px);
        /* offest in the X direction, Y direction, blur, color*/
        box-shadow: $shadow-medium;
        
        &:after {
            transform: scaleX(1.4) scaleY(1.6);
            opacity: 0;
        } 
        
    }

    &:active,
    &:focus {
        outline: none;
        transform: translateY(-1px);
        box-shadow: $shadow-small;
    }
    &::after {
        content: "";
        display: inline-block;
        height: 100%;
        width: 100%;
        border-radius: 10rem;
        // background-color: $color-white;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transition: all .4s;
    }
}