@import '../../variables';

.Home {
    height: 100%;
    background-image: linear-gradient(rgba($color-grey-dark-3, .9), rgba($color-grey-dark-3, .9)),
     url(../../../static/img/Home/boat.jpg);
    background-size: cover;
    background-position: top;
    display: grid;
    grid-template-rows: repeat(5, min-content);
    grid-template-columns: 8rem 1fr 8rem;
    grid-template-areas: "title title title"
                         ". phrase ."
                         ". subtitle ."
                         ". button ."
                         ". seen ."
                         ". coops .";
    row-gap: 7rem;

    @media only screen and (max-width: $screen-small) {
        grid-template-columns: 4rem 1fr 4rem;
    }
}


.TitleContainer {
    display: flex;
    justify-self: center;
    margin-top: 2rem;
    grid-area: title;
}
.LogoIcon {
    width: 8rem;
    height: 8rem;
}


.Text {
    text-transform: uppercase;
    font-family: $font-display;
    color: $color-primary-light;
    font-size: 2rem;
    grid-area: phrase;
}

.SeenText {
    font-size: 2rem;
    color: $color-tertiary;
    grid-area: seen;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    align-items: center;

    &::before {
        content: "";
        background-color: currentColor;
        grid-column: 1/2;
        height: 2px;
        width: 95%;
    }

    &::after {
        content: "";
        justify-self: end;
        background-color: currentColor;
        grid-column: 3/4;
        height: 2px;
        width: 95%;
    }
}
.CooperatorsContainer {
    margin-top: -1rem;
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem 1rem;
    justify-items: center;
    filter: brightness(.7);
    grid-area: coops;
    
}

.CoopsImg1,
.CoopsImg2,
.CoopsImg3,
.CoopsImg4 {
    height: 2.8vw;
}