@import '../variables';

.GalleryRoute {
    grid-row: 2 / 3;
    grid-column: 2 / 3;

    &Heading {
        text-decoration: none;
        display: inline-block;
        font-size: 3rem;
        font-family: $font-primary;
        color: $color-tertiary;
    }
}