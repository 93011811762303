@import '../../../../SASS/variables';

.Gallery {
    background-color: $color-primary-dark;
    display: grid;
    grid-template-columns: 2rem 1fr 2rem;
    grid-template-rows: 2rem 1fr 2rem;

    height: 100%;
    width: 100%;
}


.GalleryInner {
    grid-row: 2/3;
    grid-column: 2/3;

    display: grid;
    row-gap: 2rem;
    grid-template-rows: min-content min-content;
}

.AlbumsContainerOutter {
    padding: 1.5rem;
    background-color: $color-primary-medium;
    border-radius: 5px;
   

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, min-content);
    column-gap: 2px;
    row-gap: 2rem;


    .AlbumsContainerInner {
        padding: 1.5rem;
        background-color: $color-primary-medium;
        border-radius: 5px;
    
        grid-row: 2 / 3;
        grid-column: 1 / -1;
    
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
        column-gap: 2px;
        row-gap: 2rem;
        grid-auto-rows: min-content;
        
        .PillowsAlbum {
            grid-column: 1/2;
            grid-row: 1/2;
        }
        .PillowsCamIconDiv {
            position: relative;
            grid-column: 1/2;
            grid-row: 1/2;
            z-index: 1;
            margin-top: -.5rem;
            margin-left: -.5rem;
            width: 3.5rem;
            height: 3.5rem;
            background-image: linear-gradient(rgba(white, 0.3), rgba(white, 0.3));
            border-radius: 50%;
            cursor: pointer;

            .PillowsCamIcon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 2rem;
                height: 2rem;
                
            }
        }
    }


    .SeeMoreAlbum {
        font-size: 2rem;
        text-decoration: none;
        font-family: $font-display;
        color: $color-grey-dark-3;
        font-weight: 500;
        grid-row: 1 / 2;
        grid-column: 2 / 3;
        justify-self: right;
        text-decoration: none;
        transition: all .2s;
        &:hover{
            transform: translateY(-2px);
        }

        &:active {
            transform: translateY(1px);
        }
    }
}

.AlbumHeading {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    font-size: 3rem;
    font-family: $font-primary;
    color: $color-tertiary;
}




.SeeAllPhotosContainer {
    padding: 1.5rem;
    background-color: $color-primary-medium;
    border-radius: 5px;
   

    display: grid;
    row-gap: 2rem;

}
