@import '../../variables';

.NavButton {
    font-size: 1.6rem;
    font-family: $font-primary;
    font-weight: 900;
    text-transform: uppercase;
    border: none;
    outline: none;
    background-color: transparent;
    color: $color-tertiary;
    text-align: left;
    cursor: pointer;
    text-decoration: none;

    transition: opacity .2s;
}
.FontSizeGreek {
    font-size: 1rem;
    @media only screen and (max-width: $screen-small) {
        font-weight: 100 !important;
    }
}

.Navigation {
    grid-row: 1/2;
    grid-column: 3/4;
    justify-self: start;
    z-index: 1;
    white-space: nowrap;

    @media only screen and (max-width: $screen-small) {
        grid-column: 3/4;
        padding-right: 1rem;
        font-size: 1rem;
    }

    @media only screen and (max-width: $screen-smallestest){
        display: none;

    }
}
