@import '../variables';




.Input {
    width: 100%;
}

.TextArea {
    width: 100%;
}


.Input,
.TextArea {
    font-size: $font-primary-size;
    font-family: $font-display;
    color: $color-grey-dark-3;
    padding: 1.5rem 2rem;
    border-radius: 2px;
    background-color: rgba(#fff, .5);
    border: none;
    border-bottom: 3px solid transparent;
    display: block; 
    transition: all .3s;





    &::-webkit-input-placeholder {
        color: $color-grey-dark-2;
    }

    &:focus {
        outline: none;
        box-shadow: 0 1rem 2rem rgba(black, .1);
        border-bottom: 3px solid $color-primary-light;

        &:invalid {
            border-bottom: 3px solid $color-secondary;
        }
    }
}



.Label {
    font-size: 1.2rem;
    font-weight: 700;
    margin-left: 2rem;
    margin-top: .7rem;
    display: block;
    transition: all .3s;
}

.Input:placeholder-shown + .Label,
.TextArea:placeholder-shown + .Label {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4rem);
}