@import '../variables';
.primaryMedium {
    color: $color-primary-medium;
}

.white {
    color: white;
}

.NavGoldenLogo {
    grid-area: golden-logo;
    height: min-content;
    align-self: center;
    justify-self: center;
    width: 120px;
    margin-top: 20px;
    margin-left: 70px;

    @media only screen and (max-width: $screen-medium) {
        width: 100px;
        margin-left: 60px;
        margin-top: 15px;
    }
    @media only screen and (max-width: $screen-small) {
        width: 80px;
        margin-left: 50px;
        margin-top: 10px;
    }
    @media only screen and (max-width: $screen-smallest) {
        width: 60px;
        margin-left: 40px;
        margin-top: 5px;
    }
}

.LogoContainer {
    align-self: center;
    display: grid;
    grid-template-columns: repeat(2, min-content);
    grid-template-rows: repeat(3, min-content);
    grid-template-areas: "x covers"
                         "x needle"
                         "x allAbout"
                         "golden-logo golden-logo";
    cursor: pointer;

    .LogoNeedle {
        width: 12rem;
        height: 2rem;
        grid-area: needle;
        justify-self: center;
        margin-left: -1rem;
        margin-top: .2rem;
    }
    .CoverText {
        font-weight: 600;
        line-height: 2rem;
        font-size: 3rem;
        grid-area: covers;
        align-self: center;
        color: currentColor;
    }
    .AllAboutText {
        font-family: Parisienne;
        font-size: 1.5rem;
        line-height: .5rem;
        grid-area: allAbout;
        color: currentColor;
    }
    .LogoX {
        width: 9rem;
        height: 6rem;
        grid-area: x;
        margin-top: -1.6rem;
    }
}

