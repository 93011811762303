@import '../../variables';

.About {
    display: grid;
    grid-template-rows: 40rem min-content min-content;
    row-gap: 3rem;
}

.ImgContainer {
    display: block;
    background-image: linear-gradient(rgba(black, .5), rgba(black, .5)),
                    url(https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fkales%2FaboutAs.jpg?alt=media&token=ecb1a2b5-6ae2-4aea-a86a-ab7f2336b8c8);
    background-size: cover;
    background-position: center;
}

.Heading3 {
    justify-self: center;
    color: $color-grey-dark-3;
    font-size: 300;
    font-family: $font-primary;
}

.Paragraph {
    text-align: center;
    width: 50%;
    justify-self: center;
    color: $color-grey-dark-1;
    font-size: $font-primary-size;

    &::before {
        content: "";
        margin: 0 auto;
        margin-bottom: 3rem;
        display: block;
        width: 30rem;
        height: 8rem;
        mask-image: url(../../../static/img/about/separator.svg);
        mask-size: cover;
        background-color: $color-primary-light;
    }
}