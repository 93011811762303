@import '../variables';

.BgVideo {
    filter: saturate(1);
    opacity: .4;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.Video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Contact {
    grid-row: 1/-1;
    grid-column: 1/-1;
}
