@import '../variables';

.AlbumItem {
    
    &Heading {
        font-size: 2rem;
        font-family: $font-primary;
        color: $color-tertiary;
        line-height: 1.5rem;
    }

    .RepImg {
        display: block;
        width: 20.5rem;
        height: 20.5rem;
        border-radius: 5px;
        margin-bottom: 1rem;
        box-shadow: $shadow-small;
        transition: all .2s;
        cursor: pointer;

        &:hover {
            transform: translateY(-.2rem);
            box-shadow: $shadow-medium;
        }

        &:active {
            transform: translateY(.1rem);
            box-shadow: $shadow-smallest;
        }
    }
    
    & span {
        font-size: 1.4rem;
        font-family: $font-primary;
        color: $color-tertiary;
    }
}
