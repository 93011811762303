@import '../../variables';


$animation-time-prime: 1.5s;
$animation-time-second: 1.5s;
.LandingPage {
    position: relative;    
    width: 100vw;
    height: 100vh;  
    background-color: $color-tertiary;
    // &TransExited {
    //     // opacity: 0;
    // }
}

.Background,
.BackgroundTransExiting {
    background-image: radial-gradient(rgba($color-primary-light, .8), rgba($color-primary-dark, .9));
    width: 6rem;
    height: 6rem;
    z-index: 100;
    border-radius: 50%;
    align-self: center;
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    transition: transform $animation-time-prime ease-out;

}

.Background {
    transform: scale(80);

    &TransExiting {
        transform: scale(1);
    }

    // &TransExited {

    // }
}



.Content,
.ContentTransExiting,
.ContentTransExited {
    
    background-size: cover;
    background-position: top;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 200;

    -webkit-clip-path: polygon(0 0,    100% 0,    100% 75vh,    0 100%);
    clip-path: polygon(0 0,    100% 0,    100% 75vh,    0 100%);

    @media only screen and (max-width: $screen-small) {
        -webkit-clip-path: polygon(0 0,    100% 0,    100% 85vh,    0 100%);
        clip-path: polygon(0 0,    100% 0,    100% 85vh,    0 100%);
    }

    transition: animation $animation-time-prime ease-in .4s;

}

.Content {
    background-image: url(../../../static/img/LandingPage/speedboat.jpg);

    &TransExiting {
        background-image: url(../../../static/img/LandingPage/speedboat.jpg);
        animation: changeBackground 2.2s;
    }
    // &TransExited {
    //     // opacity: 0;
    // }
}

.HeadersContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    // transition: transform $animation-time-second cubic-bezier(.86, 0, .07, 1);

    &TransExiting {
        z-index: 3000;
        animation: moveOutUpperLeft $animation-time-prime;
    }
}
.HeadingContainer {
    display: flex;
    align-items: center;
    padding-right: 5rem;
}

.Logo {
    width: 12rem;
    height: 12rem;
    animation: moveInUpperLeft .5s ease-out .75s backwards;
    z-index: 200;
}


.ButtonBackgroundContainer {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
}