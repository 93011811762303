@import '../../../../SASS/variables';

.Backdrop {
    background-image: linear-gradient(rgba(black, .5), rgba(black, .5));
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 999;
    backdrop-filter: blur(10px);
}


.Modal {
    background-color: black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 80%;
    overflow: scroll;
}

.SelectedImgContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    width: 80%;
    height: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .SelectedImg {
        grid-row: 1/2;
        grid-column: 1/2;
        width: 100%;
        height: 100%;
    }

    .ButtonsContainer {
        background-image: linear-gradient(rgba(black, .5), rgba(black, .5));
        height: 3rem;
        width: 100%;
        position: relative;
        z-index: 999;
        grid-row: 1/2;
        grid-column: 1/2;
        justify-self: center;
        align-self: end;
        display: grid;
        justify-content: center;
        grid-template-columns: 1fr 1fr;
        align-content: center;
        button {
            width: 50%;
            min-width: 5rem;
            justify-self: center;
            border-radius: 1rem;
            cursor: pointer;
            transition: all .1s;
            &:hover {
                transform: translateY(-1px);
            }
            &:focus {
                outline: 0;
            }
            &:active {
                transform: translateY(.5px);
            }
        }
        
        .ButtonSet {
            background-color: $color-primary-dark; 
            color: white;
        }
    
    
        .ButtonCancel {
            background-color: $color-quatermary; 
            color: white;   
            
        }
    }

}