@import '../variables';

.FiveStarReview:hover  .Star1,
.FiveStarReview:hover  .Star2,
.FiveStarReview:hover  .Star3,
.FiveStarReview:hover  .Star4,
.FiveStarReview:hover  .Star5{
    color: $color-primary-dark;
}

.Star1:hover ~ svg,
.Star2:hover ~ svg,
.Star3:hover ~ svg,
.Star4:hover ~ svg {
    color: $color-primary-medium !important;
}


.Star1,
.Star2,
.Star3,
.Star4,
.Star5 {
    width: 3rem;
    height: 3rem;
    color: $color-primary-medium;
}



.Selected {
    color: $color-primary-dark;
}