@import '../../variables';

.Heading2 {
    font-size: 3.5rem;
    font-family: $font-primary;
    font-weight: 300;
    text-transform: uppercase;
}


.LandingPage {
    color: $color-tertiary;
    line-height: 5rem;
    z-index: 200;
    animation: moveInLeft .8s ease-in;

    @media only screen and (max-width: 37.5em){ 
        margin-top: -1.8rem;
       }; // 600px
}


.Home {
    line-height: 1rem;
    align-self: center;
    color: $color-tertiary;
    grid-area: subtitle;
}


.ContactForm {
    background-image: linear-gradient(to bottom right, $color-primary-light, $color-primary-dark);
    color: transparent;
    grid-column: 1 / 3;
    justify-self: center;
    font-family: $font-display;
    font-weight: 400;
    background-clip: text;
}


.FeedbackForm {
    background-image: linear-gradient(to bottom right, $color-primary-light, $color-primary-dark);
    color: transparent;
    grid-area: heading;
    justify-self: center;
    font-family: $font-display;
    font-weight: 400;
    background-clip: text;
}