@import './variables';

body {
  margin: 0;
  font-family: $font-primary, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to right bottom, $color-primary-dark, $color-primary-light);
  background-size: cover;
  font-weight: 300;
  line-height: 1.6;

}

code {
  font-family: $font-primary, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html {
  translate: "no";
  box-sizing: border-box;
  font-size: 62.5%; // 1rem == 10px because 10/16 = 62.5%
  @media only screen and (min-width: $screen-largest){ 
    font-size: 70%; // we want 1rem = 8px, so, 8/16 = 50% 
   };
   @media only screen and (max-width: $screen-largest){ 
    font-size: 62.5%; // we want 1rem = 8px, so, 8/16 = 50% 
   };
   @media only screen and (max-width: $screen-medium){ 
    font-size: 50%; // we want 1rem = 8px, so, 8/16 = 50% 
   };
   @media only screen and (max-width: $screen-small){ 
    -webkit-text-size-adjust: none;
    font-size: 43%; // we want 1rem = 8px, so, 8/16 = 50% 
   };
  @media only screen and (max-width: $screen-smallest){ 
    -webkit-text-size-adjust: none;
    font-size: 35%; // we want 1rem = 8px, so, 8/16 = 50% 
   }; 
}