@import '../variables';
.Small {
    margin-bottom: $margin-small;
}


.Medium {
    margin-bottom: $margin-medium;
}


.Large {
    margin-bottom: $margin-large;
}


.Huge {
    margin-bottom: $margin-huge;
}

