@import '../../SASS//variables';

.LoginFormBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5));
    z-index: 999;
    backdrop-filter: blur(10px);
    cursor: pointer;
}

.LoginFormModal {
    cursor: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 80%;
    background-color: $color-tertiary;
    border-radius: 1.5rem;
    overflow: hidden;
    display: grid;
    grid-template-areas: ". title ." ". error ." ". username ." ". password ." ". btn .";
    grid-template-rows: 15rem min-content min-content min-content 1fr;
    grid-template-columns: 5rem 1fr 5rem;
    row-gap: 2rem;
}

.Heading3 {
    grid-area: title;
    color: $color-primary-light;
    justify-self: center;
    align-self: center;
}

.UsernameContainer {
    grid-area: username;
    justify-self: center;
    .Username {
    }
}

.PasswordContainer {
    grid-area: password;
    justify-self: center;
    .Password {
    }
}

.Btn {
    grid-area: btn;
    width: 15rem;
    height: 5rem;
    border-radius: 10px;
    align-self: center;
    justify-self: center;
}

.Error {
    grid-area: error;
    justify-self: center;
    color: $color-quatermary;
    font-size: 2rem;
}

.BtnLogout {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-5%, -8%);
    width: 15rem;
    height: 5rem;
    border-radius: 10px;
    font-size: 3rem;
}