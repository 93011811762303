@import '../variables';

.Footer {
    position: relative;
    grid-area: footer;
    background-color: $color-grey-dark-3;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: flex-end;

    grid-template-areas: ". links copy";

    // &::after{
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     left: 50%;
    //     transform: translateX(-50%);
    //     margin: 0 auto;
    //     margin-bottom: 3rem;
    //     display: block;
    //     width: 20rem;
    //     height: 5rem;
    //     mask-image: url(../../static/img/about/separator.svg);
    //     mask-size: cover;
    //     background-color: $color-primary-light-1;
    // }
}


.LinksContainer {
    display: grid;
    grid-template-columns: repeat(6, max-content);
    margin-left: -2.5rem;

    column-gap: 1rem;
    grid-area: links;

    justify-self: center;
}
.Link {
    font-size: $font-primary-size;
    color: $color-primary-light-1;
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }

    }


.Copyrights {
    grid-area: copy;
    justify-self: right;
    align-self: center;
    color: $color-primary-light-1;
    text-align: center;
}


.SocialMedia {
    display: grid;
    grid-template-columns: min-content;
    margin: auto 1rem;
}

/* Style all font awesome icons */
.Fa {
    padding: .5rem;
    font-size: 1rem;
    width: 2rem;
    height: 2rem;
    text-align: center;
    text-decoration: none;
    border-radius: 50%;

  }
  
  /* Add a hover effect if you want */
  .Fa:hover {
    opacity: 0.7;
  }
  
  /* Set a specific color for each brand */
  
  /* Facebook */
  .FaFacebook {
    background: #3B5998;
    color: white;
  }

