@import '../variables';

.Header {
    background-color: $color-primary-light-1; //$color-grey-light-2;
    grid-area: header;
    display: grid;
    grid-template-columns: min-content 1fr max-content;
    // border-bottom: 2px solid $color-grey-light-1;
}


.SearchBoxContainer {
    justify-self: center;
    align-self: center;  
    display: grid; 
    width: 80%;
}


.SearchBoxForm {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    display: flex;
    &:focus {
        outline: none;
        width: 100%;
        background-color: $color-primary-light-2;
    }
}

.SearchBox {
    width: 90%;
    border-radius: 100px;
    padding: 1rem;
    font-size: $font-primary-size;
    font-family: inherit;
    color: $color-grey-dark-3;
    outline: none;
    border: none;
    background-color: #fff;
    transition: all .2s;
    justify-self: center;
    
    &:focus {
        outline: none;
        width: 100%;
        background-color: $color-primary-light-2;
    }
    &::-webkit-input-placeholder {
        font-weight: 200;
        color: $color-grey-dark-2;
    }
}
.SearchBox:focus + [class*="Header"]{
    transform: translateX(-3rem);
}

