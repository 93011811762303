@import '../../variables';

.Heading1 {
    text-transform: uppercase;
    font-size: 8rem;
    font-weight: 300;
    font-family: $font-display;
}


.LandingPage {
    color: $color-tertiary;
    animation: moveInRight .8s ease-in;
    z-index: 200;
}