@import '../variables';

.ErrorModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(rgba(black, .5), rgba(black, .5));
    backdrop-filter: blur(10px);
    z-index: 3000;


    .InnerContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50%;
        height: 50%;
        background-color: $color-primary-light-1;
        z-index: 3001;
        border-radius: 2rem;
        box-shadow: $shadow-medium;

        display: grid;
        grid-template-columns: 4rem 1fr 4rem;
        grid-template-rows: repeat(4, 1fr);
        grid-template-areas: "space-left error space-right"
                            "space-left msg space-right"
                            "space-left emoji space-right"
                            "space-left btn space-right";
        justify-items: center;
        align-items: center;

        animation: slideInTop .5s;
    }

    .Error {
        font-size: 3rem;
        grid-area: error;
        color: $color-primary-dark;
        align-self: flex-end;
    }

    .Msg {
        grid-area: msg;
        font-size: 2rem;
    }
    
    .Emoji {
        grid-area: emoji;
        font-size: 4rem;
    }

    .Btn {
        grid-area: btn;
        background-color: $color-primary-dark;
        padding: 2rem 3rem;
        border-radius: 1rem;
    }
}