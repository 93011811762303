@import "../../variables";

.Heading3 {
    font-family: $font-display;
    font-size: 5rem;
    font-weight: 300;
    line-height: 6rem;
}



.Home {

}