@import '../variables';

.FlippingCard {
    // FUNCTIONALITY

    // the perspective property must be defined in the parent elemnt and we give it a huge number of pxs (the 150rem in the current is 1500px)
    // the lower the value the more dramatic the effect is.
    // The effect is to see the card flipping like it is really coming towards the user
    perspective: 150rem;
    -moz-perspective: 150rem; // we need that for mozilla users
    position: relative;
    height: 52rem;
    .Side {
        font-size: 2rem;
        height: 52rem;
        transition: all .8s ease;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        border-radius: 3px;
        box-shadow: 0 1.5rem 4rem rgba(black, .15);
        overflow: hidden;

        & > * {
            box-shadow: none;
        }
        &.FrontSide {
            background-color: $color-tertiary;
        }
        
        &.BackSide {
            transform: rotateY(180deg);
            &.Card1 {
                background-image: linear-gradient(
                to right bottom, 
                rgba($color-secondary-light, .5), 
                rgba($color-secondary-dark, .5)), 
            url("https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fcovers%2F116268499_284768142618549_7941180810193235714_n.jpg?alt=media&token=ac84b596-4e37-43bf-b645-f0906402bc64");
                background-position: center;
            }

            &.Card2 {
                background-image: linear-gradient(
                to right bottom, 
                rgba($color-primary-light, .5), 
                rgba($color-primary-dark, .5)), 
            url("https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Ftents%2F116054999_285239239203219_1123578843934209105_n.jpg?alt=media&token=5d165062-7d96-4d16-9d6b-29af8efae24c");
                background-position: center;
            }

            &.Card3 {
                background-image: linear-gradient(
                to right bottom, 
                rgba($color-tertiary-light, .5), 
                rgba($color-tertiary-dark, .5)), 
            url("https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F115804201_286884269059790_1434541825025409592_n.jpg?alt=media&token=7b0558d9-4df7-4fa6-9e82-f3765c0a6b7f");

            }
        }

    }

    .ButtonSecondary {
        padding: 1rem 3rem;
        background-color: $color-tertiary;
        color: $color-grey-dark-3;
    }

    &:hover .FrontSide {
        transform: rotateY(-180deg);
    }

    &:hover .BackSide {
        transform: rotateY(0);
    }

    // FRONT SIDE STYLING
    .CardPicture {
        background-size: cover;
        height: 23rem;
        background-blend-mode: screen;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
        clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;

        &.CardPicture1 {
            background-image: linear-gradient(
                to right bottom, 
                $color-secondary-light, 
                $color-secondary-dark), 
            url("https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fcovers%2F116268499_284768142618549_7941180810193235714_n.jpg?alt=media&token=ac84b596-4e37-43bf-b645-f0906402bc64");
            background-position: center;
            
        }

        &.CardPicture2 {
            background-image: linear-gradient(
                to right bottom, 
                $color-primary-light, 
                $color-primary-dark), 
            url("https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Ftents%2F116054999_285239239203219_1123578843934209105_n.jpg?alt=media&token=5d165062-7d96-4d16-9d6b-29af8efae24c");
        }

        &.CardPicture3 {
            background-image: linear-gradient(
                to right bottom, 
                $color-tertiary-light, 
                $color-tertiary-dark), 
            url("https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fpillows%2F115804201_286884269059790_1434541825025409592_n.jpg?alt=media&token=7b0558d9-4df7-4fa6-9e82-f3765c0a6b7f");
        }
    }

    .CardHeading {
        font-size: 2.8rem;
        font-weight: 300;
        text-align: right;
        text-transform: uppercase;
        color: #fff;
        position: absolute;
        top: 12rem;
        right: 2rem;
        width: 75%;
    }

    .HeadingSpan {
        padding: 1rem 1.5rem;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
        &.Span1 {
            background-image: linear-gradient(to right bottom, rgba($color-secondary-light, .85), rgba($color-secondary-dark, .85));
        }

        &.Span2 {
            background-image: linear-gradient(to right bottom, rgba($color-primary-light, .85), rgba($color-primary-dark, .85));
        }

        &.Span3 {
            background-image: linear-gradient(to right bottom, rgba($color-tertiary-light, .85), rgba($color-tertiary-dark, .85));
        }
    }

    .CardDetails {
        padding: 3rem;
        ul {
            list-style-type: none;
            width: 80%; 
            margin: 0 auto;            
            li {
                text-align: center;
                font-size: 1.5rem;
                padding: 1rem;

                &:not(:last-child) {
                    border-bottom: 1px solid $color-grey-light-1;
                }
            }
        }
    }

    // BACK SIDE STYLING
    .CTA {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        text-align: center;
    }

    .PriceBox {
        text-align: center;
        margin-bottom: 8rem;
        color: #fff;
    }

    .PriceOnly {
        font-size: 1.4rem;
        text-transform: uppercase;
    }

    .PriceValue {
        font-size: 6rem;
        font-weight: 100;
    }


}
