// COLORS
$color-primary-light-1: #e4eff1;
$color-primary-light-2: #c8e3e9;
$color-primary-light: #8fc1e3;
$color-primary-medium: #5085a5;
$color-primary-dark: #31708e;

$color-secondary:#687864;
$color-tertiary: $color-primary-light-1;//#f7f9fb;
$color-quatermary: #A45A52;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark-1: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-secondary-light: #ffb900;
$color-tertiary-light: #7ed56f;

$color-secondary-dark: #ff7730;
$color-tertiary-dark: #28b484;

// FONT VARIABLES
$font-primary: 'Nunito','Manrope', sans-serif;
$font-display: 'Josefin Sans', 'Alegreya Sans', sans-serif;


// MARGINS
$margin-small: 2rem;
$margin-medium: 3rem;
$margin-large: 5rem;
$margin-huge: 8rem;

// FONTS
$font-primary-size: 1.6rem;

// SHADOWS
$shadow-smallest: 0 .25rem 1rem rgba(black, .2);
$shadow-small: 0 .5rem 1rem rgba(black, .2);
$shadow-medium: 0 1rem 2rem rgba(black, .2);
$shadow-intense: 0 1px 2px black;

// PAGE SIZES
// general
// landing page
$screen-smallestest: 20rem; // 304px
$screen-smallest: 30em; // around 480px
$screen-small: 37.5em; // 600px
$screen-medium: 56.25em; // 900px
$screen-large: 75em; // 1200px
$screen-largest: 93.75em; // 1500px

// ANIMATIONS
@keyframes moveInBottom {
    0% {
        opacity: 0;
        transform: translateY(3rem);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes moveInLeft {
    0% {
        opacity: 0;
        transform: translateX(-10rem);
    }
    80% {
        transform: translateX(1rem);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes moveInRight {
    0% {
        opacity: 0;
        transform: translateX(10rem);
    }
    80% {
        transform: translateX(-1rem);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes moveInUpperLeft {
    0% {
        opacity: 0;
        transform: translate(-10rem, -10rem);
    }
    80% {
        transform: translate(.5rem, .5rem);
    }
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

@keyframes moveOutUpperLeft {
    50% {
        opacity: 1;
        transform: translate(10rem, 10rem);
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translate(-50%, -50%);
    }
}

@keyframes changeBackground {
    // 0% {
    //     background-image: radial-gradient(rgba($color-primary-light, .9), rgba($color-primary-dark, .9));
    // }
    0% {
        
        }
    100% {
        background-image: none;
    }

}

@keyframes fadeIn { // used first in layout
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


@keyframes slideInTop {
    0% {
        transform: translate(-50%, -200%);
    }

    100% {
        transform: translate(-50%, -50%);
    }
}