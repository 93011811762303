@import '../../../../SASS/variables';

.GalleryRoute {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    &Heading {
        text-decoration: none;
        display: inline-block;
        font-size: 3rem;
        font-family: $font-primary;
        color: $color-tertiary;
    }
    button {
        width: 7rem;
        justify-self: center;
        border-radius: 1rem;
        cursor: pointer;
        transition: all .1s;
        &:hover {
            transform: translateY(-1px);
        }
        &:focus {
            outline: 0;
        }
        &:active {
            transform: translateY(.5px);
        }
    }
}

.ButtonsContainer {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    justify-self: right;
    width: 20rem;
    display: flex;
    justify-content: space-between;

    .ButtonAdd {
        background-color: $color-primary-dark; 
        color: white;
    }


    .ButtonRemove {
        background-color: $color-quatermary; 
        color: white;   
        
    }
}

.PathContainer {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
}