@import '../../variables';

.Feedback {
    width: 100%;
    height: 100%;
    position: relative;
    display: grid;
    grid-template-rows: 4rem 1fr 4rem;
    grid-template-columns: 1fr max-content 1fr;
    grid-template-areas: ". . ." ". form ." ". . .";
    background-image: linear-gradient(rgba(black, 0.4), rgba(black, 0.4)),
    url(https://firebasestorage.googleapis.com/v0/b/xcovers-1780f.appspot.com/o/gallery%2Fkales%2FaboutAs.jpg?alt=media&token=ecb1a2b5-6ae2-4aea-a86a-ab7f2336b8c8);                     ;
    background-size: cover;
}

.FeedbackForm{
    grid-area: form;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba($color-tertiary, $alpha: .8);
    z-index: 19;
    padding: 2rem 2rem;
    box-shadow: $shadow-smallest;
    display: grid;
    grid-template-columns: max-content max-content;
    column-gap: 2rem;
    row-gap: 2rem;
    transition: all .1s;   
}

// work confidentiallity
.NullNull {
    grid-template-areas: "heading heading" 
                         "worked worked"
                         "confid confid";
    grid-template-rows: repeat(3, min-content);
}
.YesNull {
    grid-template-areas: "heading heading" 
                          "worked worked"
                          "confid confid";
    grid-template-rows: repeat(3, min-content);
}
.NullYes {
    grid-template-areas: "heading heading" 
                         "worked worked"
                         "confid confid"
                         "fullname email";
    grid-template-rows: repeat(4, min-content);
}
.NullNo {
    grid-template-areas: "heading heading" 
                         "worked worked"
                         "confid confid";
    grid-template-rows: repeat(4, min-content);
}
.NoNull {
    grid-template-areas: "heading heading" 
                         "worked worked"
                         "confid confid";
    grid-template-rows: repeat(4, min-content);
}

.ΝοΝο {
    grid-template-areas: "heading heading" 
                         "worked worked"
                         "confid confid"
                         "comment comment"
                         "btn btn";
    grid-template-rows: repeat(5, min-content);
}
.YesNo {
    grid-template-areas: "heading heading" 
                         "worked worked"
                         "confid confid"
                         "serviceRating serviceRating"
                         "satisfactionRating satisfactionRating"
                         "friendlyRating friendlyRating"
                         "reccomendRating reccomendRating"
                         "comment comment"
                         "btn btn";
    grid-template-rows: repeat(9, min-content);
}
.NoYes {
    grid-template-areas: "heading heading" 
                         "worked worked"
                         "confid confid"
                         "fullname email"
                         "comment comment"
                         "btn btn";
    grid-template-rows: repeat(6, min-content);
}

.YesYes {
    grid-template-areas: "heading heading" 
                         "worked worked"
                         "confid confid"
                         "fullname email"
                         "serviceRating serviceRating"
                         "satisfactionRating satisfactionRating"
                         "friendlyRating friendlyRating"
                         "reccomendRating reccomendRating"
                         "comment comment"
                         "btn btn";
    grid-template-rows: repeat(10, min-content);
}

.RadioButtonTitle {
    font-size: 2rem;
    font-family: $font-display;
    margin-right: 2rem;
    
}
.Confidentiality {
    grid-area: confid;
    display: flex;
    justify-content: space-between;
}
.WorkedQuestion {
    grid-area: worked;
    display: flex;
    justify-content: space-between;
}

.NameContainer {
    grid-area: fullname;
}

.EmailContainer {
    grid-area: email;
}

.Comment {
    grid-area: comment;
}



.ServiceRating {
    grid-area: serviceRating;
    display: flex;
    justify-content: space-between;
}

.SatisfactionRating {
    grid-area: satisfactionRating;
    display: flex;
    justify-content: space-between;
}
.FriendlyRating {
    grid-area: friendlyRating;
    display: flex;
    justify-content: space-between;
}
.ReccomendRating {
    grid-area: reccomendRating;
    display: flex;
    justify-content: space-between;
}

.ButtonSecondary {
    justify-self: center;
    grid-area: btn;
    font-size: $font-primary-size;
    padding: 1rem 4rem;
    font-family: $font-display;
    margin-top: 2rem;
}



.SubmittedContainer {
    margin-top: 50%;
    grid-row: 1 / -1;
    justify-self: center;
    align-self: center;
    width: 100%;
    display: grid;
    row-gap: 2rem;
    grid-template-columns: 1fr;
    .MessageSentText {
        text-align: center;
        color: $color-primary-dark;
    }

    .SentIcon {
        border: 2px solid $color-primary-light;
        fill: $color-primary-light;
        width: 25rem;
        height: 15rem;
        justify-self: center;
    }

}