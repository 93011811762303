@import '../variables';

.ContactForm {
    position: relative;
    width: 100%;
    background-color: rgba($color-tertiary, $alpha: .8);
    z-index: 19;
    padding: 2rem 8rem;
    box-shadow: $shadow-smallest;
    display: grid;
    grid-template-columns: max-content max-content max-content min-content;
    grid-template-rows: repeat(5, min-content);
    column-gap: 2rem;
    row-gap: 4rem;
    transition: width .1s;
}

.ShowDetails{
    width: 100%;
}

.HideDetails {
    width: 58%;
    overflow: hidden;
}

.Contact { // section contact
    grid-row: 2 / 3;
    grid-column: 2 / 3;
}

.MeansOfCommunicationText {
    display: block;
    font-size: $font-primary-size;
    margin-bottom: 1rem;
    color: $color-secondary;
    font-family: $font-display;
}


.ButtonSecondary {
    font-size: $font-primary-size;
    padding: 1rem 4rem;
    font-family: $font-display;
    margin-top: 2rem;
}

.ContactDetailsToggle {
    width: 4rem;
    grid-row: 1 / -1;
    grid-column: 3 / 4;
    justify-self: center;
    align-self: center;
    transform: rotate(90deg);
    white-space: nowrap;
    text-align: center;
    border: none;
    outline: none;
    color: $color-primary-light;
    transition: all .2s;
    cursor: pointer;
    &:hover {  
        transform: rotate(90deg) translateY(-.2rem);        
        color: $color-primary-medium;
    }

    &:visited,
    &:active {
        color: $color-primary-dark;
    }
}



.Location {
    grid-row: 2 / -1;
    grid-column: 4 / 5;
    display: grid;
    & span {
        display: block;
        font-size: 1.6rem;
        color: $color-secondary;
        
        &:not(:last-child) {
            margin-bottom: 2rem;
        }
        &:first-of-type {
            margin-top: 4rem;
        }
    }
}


.ContactAddress {
    &::before {
        content: "";
        display: inline-block;
        height: 2rem;
        width: 2rem;
        margin-right: .7rem;
        background-color: $color-primary-light;
        mask-image: url(../../static/img/SVG/SVG/location-pin.svg);
        mask-size: cover;
    }
}

.ContactPhone {
    &::before {
        content: "";
        display: inline-block;
        height: 2rem;
        width: 2rem;
        margin-right: .7rem;
        background-color: $color-primary-light;
        mask-image: url(../../static/img/SVG/SVG/phone.svg);
        mask-size: cover;
    }
}
.ContactTelephone {
    &::before {
        content: "";
        display: inline-block;
        height: 2rem;
        width: 2rem;
        margin-right: .7rem;
        background-color: $color-primary-light;
        mask-image: url(../../static/img/SVG/SVG/telephone.svg);
        mask-size: cover;
    }
}


.ContactEmail {
    &::before {
        content: "";
        display: inline-block;
        height: 2rem;
        width: 2rem;
        margin-right: .7rem;
        background-color: $color-primary-light;
        mask-image: url(../../static/img/SVG/SVG/mail.svg);
        mask-size: cover;
    }
}

.ContactFB {
    & a:link,
    & a:active,
    & a:visited {
        text-decoration: none;
        color: $color-secondary;
    }

    &::before {
        content: "";
        display: inline-block;
        height: 2rem;
        width: 2rem;
        margin-right: .7rem;
        background-color: $color-primary-light;
        mask-image: url(../../static/img/SVG/SVG/share2.svg);
        mask-size: cover;
        cursor: pointer;
    }
}
.Heading3ContantDetails{
    font-size: 3.5rem;
    grid-row: 1 / 2;
    grid-column: 4 / 5;
    color: $color-secondary;
    text-align: center;
}

.SubmittedContainer {
    grid-row: 1 / 6;
    grid-column: 1 / 2;
    justify-self: center;
    align-self: center;
    width: 100%;
    display: grid;
    row-gap: 2rem;
    grid-template-columns: 1fr;
    .MessageSentText {
        text-align: center;
        color: $color-primary-dark;
    }

    .SentIcon {
        border: 2px solid $color-primary-light;
        fill: $color-primary-light;
        width: 25rem;
        height: 15rem;
        justify-self: center;
    }

    .ButtonSecondarySendAgain {
        grid-template-columns: auto;
        grid-template-rows: auto;
    }
}


// loading spinner 
.lds-dual-ring {
    grid-row: 1 / 6;
    grid-column: 1 / 2;
    justify-self: center;
    align-self: center;
    display: inline-block;
    width: 80px;
    height: 80px;
    
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid $color-primary-dark;
    border-color: $color-primary-dark transparent $color-primary-dark transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  

@media only screen and (max-width: $screen-large) {
    .ContactForm {
        grid-template-columns: max-content max-content ;
        grid-template-rows: repeat(5, min-content);
    }

    .SubmittedContainer { 
        grid-column: 1 / -1;
        grid-row: 1/7;
    }

    .lds-dual-ring {
        grid-row: 1 / 7;
        grid-column: 1 / -1;   
      }

    .Heading3ContantDetails {
        font-size: 3.5rem;
        grid-row: 7 / 8;
        grid-column: 1 / -1;
        color: $color-secondary;
        text-align: center;
    }

    .ContactDetailsToggle {
        display: none;
    }

    .Location {
        grid-column: 1 / -1;
        grid-row: 8 / span 2;
    }

    @media only screen and (max-width: $screen-smallest) {
        .ContactForm {
            padding: 2rem 2rem;
        }
    
    }
    
}











