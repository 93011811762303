@import '../variables';

// .SVGIcon {
//  width: 10rem;
// }

.LandingPage {
    position: relative;
    width: 4rem;
    height: 4rem;
    fill: $color-secondary;
    z-index: 200;
}


.Header {
    width: 2.75rem;
    height: 2.75rem;
    margin-right: 1rem;
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    justify-self: end;
    align-self: center;
    transform: translateX(-3rem);
    transition: all .2s;
    fill: $color-grey-dark-2;
    cursor: pointer;

    @media only screen and (max-width: $screen-smallest) {
        display: none;
    }
    @media only screen and (min-width: $screen-large) {
        transform: translateX(-5rem);
    }
}


.Navigation {
    width: 1.75rem;
    height: 1.75rem;
    fill: $color-tertiary;
    grid-column: 2/3;
    grid-row: 1/2;
    z-index: 1;

    @media only screen and (max-width: $screen-small) {
        grid-column: 2/3;
        margin-right: 2px;
     //   margin-left: 5px;
    }

}

.OnlyGreek {
    @media only screen and (max-width: $screen-small) {   
        display: none;
    }
}