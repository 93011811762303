@import '../../SASS/variables';

.RadioButton {
    width: 33.3333%;
    display: flex;

    
    .Label {
        font-size: $font-primary-size;
        cursor: pointer;
        position: relative;
        padding-left: 2.5rem;

        .Span {
            height: 2rem;
            width: 2rem;
            border: 5px solid $color-primary-light;
            border-radius: 50%;
            display: inline-block;
            position: absolute;
            left: 0;

    
            &::after {
                content: "";
                display: block;
                height: 1.3rem;
                width: 1.3rem;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: $color-primary-light;
                opacity: 0;
                transition: opacity .2s;
            }
        }
    }

    .Input {
        display: none;
    }
    .Input:checked ~ .Label .Span::after {
        opacity: 1;
    }
}