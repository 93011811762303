@import '../../../../SASS/variables';

.AlbumItem {
    .CameraIconContainer:hover {
        transform: translateY(-.2rem);
        box-shadow: $shadow-medium;
    }
    .CameraIconContainer:active {
        transform: translateY(.2rem);
        box-shadow: $shadow-medium;
    }
    .CameraIconContainer {
        position: relative;
        width: 3rem;
        height: 3rem;
        margin-bottom: -3.2rem;
        margin-left: .2rem;
        z-index: 10;
        background-image: linear-gradient(rgba(white, .3), rgba(white, .3));
        border-radius: 50%;
        transition: all .2s;
        cursor: pointer;

        .CameraIcon {
            fill: $color-tertiary;
            width: 1.8rem;
            height: 1.8rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -53%);
        }
    }
    &Heading {
        font-size: 2rem;
        font-family: $font-primary;
        color: $color-tertiary;
        line-height: 1.5rem;
    }

    .RepImg {
        display: block;
        width: 20.5rem;
        height: 20.5rem;
        border-radius: 5px;
        margin-bottom: 1rem;
        box-shadow: $shadow-small;
        transition: all .2s;
        cursor: pointer;

        &:hover {
            transform: translateY(-.2rem);
            box-shadow: $shadow-medium;
        }

        &:active {
            transform: translateY(.1rem);
            box-shadow: $shadow-smallest;
        }
    }
    
    & span {
        font-size: 1.4rem;
        font-family: $font-primary;
        color: $color-tertiary;
    }
}
