@import '../../variables';

.ButtonSecondary {
    color: $color-tertiary;
    background-color: $color-primary-light;
    border: none;
    box-shadow: $shadow-small;
    outline: none;
    transition: all .15s;
    cursor: pointer;
    &:hover {
        transform: translateY(-.5rem);
        box-shadow: $shadow-medium;
    }
    &:active {
        transform: translateY(.1rem);
        box-shadow: $shadow-smallest;
    }
}





.UserLoginSignUp,
.UserLoginLogin {
    width: 8rem;
    height: 4rem;
}


.SelectedLanguage {
    background-color: $color-tertiary;
    color: $color-primary-light;
    outline: auto;
}

.Home {
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 300;
    font-family: $font-display;
    justify-self: start;
    border-radius: unset;
    width: 24rem;
    height: 6rem;  
    transition: all .2s;
    box-shadow: $shadow-smallest;
    grid-area: button;

    &:hover {
        transform: translateY(0);
        background-color: $color-primary-dark;
    }
}