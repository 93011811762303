@import "../../variables";

.Gallery {
  background-color: $color-primary-dark;
  display: grid;
  grid-template-columns: 2rem 1fr 2rem;
  grid-template-rows: 2rem 1fr 2rem;

  height: 100%;
  width: 100%;
}

.GalleryInner {
  grid-row: 2/3;
  grid-column: 2/3;

  display: grid;
  row-gap: 2rem;
  grid-template-rows: min-content min-content;
}

.AlbumsContainerOutter {
  padding: 1.5rem;
  background-color: $color-primary-medium;
  border-radius: 5px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, min-content);
  column-gap: 2px;
  row-gap: 2rem;

  .AlbumsContainerInner {
    padding: 1.5rem;
    background-color: $color-primary-medium;
    border-radius: 5px;

    grid-row: 2 / 3;
    grid-column: 1 / -1;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
    column-gap: 2px;
    row-gap: 2rem;
    grid-auto-rows: min-content;
  }

  .SeeMoreAlbum {
    font-size: 2rem;
    text-decoration: none;
    font-family: $font-display;
    color: $color-grey-dark-3;
    font-weight: 500;
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    justify-self: right;
    text-decoration: none;
    transition: all 0.2s;
    &:hover {
      transform: translateY(-2px);
    }

    &:active {
      transform: translateY(1px);
    }
  }
}

.AlbumHeading {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  font-size: 3rem;
  font-family: $font-primary;
  color: $color-tertiary;
}

.SeeAllPhotosContainer {
  padding: 1.5rem;
  background-color: $color-primary-medium;
  border-radius: 5px;

  display: grid;
  row-gap: 2rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.LoadingContainer {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue color */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}
