@import "../variables";

.Navigation {
  background-color: $color-secondary;
  grid-area: nav;
  position: relative;
  overflow: hidden;
  width: 20rem;
  transition: all 0.2s;

  @media only screen and (max-width: $screen-small) {
    width: 100%;
    height: 5rem;
    display: flex;
  }
}

.ClosedDrawer {
  width: 4.75rem;

  [class*="Button"] {
    opacity: 0;
  }
}

.HrNav {
  @media only screen and (max-width: $screen-small) {
    display: none;
  }
}

.NavList {
  margin-right: 3rem;
  list-style: none;
  display: grid;
  width: 100%;
  @media only screen and (max-width: $screen-small) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(7, max-content);
    grid-template-areas: "golden-logo feedback contact gallery services about home";
    justify-content: flex-end;
    justify-items: center;
  }
}

@media only screen and (max-width: $screen-small) {
  .NavList {
    li:nth-of-type(1) {
      grid-area: home;
    }
    li:nth-of-type(2) {
      grid-area: about;
    }
    li:nth-of-type(3) {
      grid-area: services;
    }
    li:nth-of-type(4) {
      grid-area: gallery;
    }
    li:nth-of-type(5) {
      grid-area: contact;
    }
    li:nth-of-type(6) {
      grid-area: feedback;
    }
  }
}

.NavItem {
  display: grid;
  grid-template-columns: 3px min-content 1fr;
  grid-template-rows: 4.5rem;
  column-gap: 1.35rem;
  align-items: center;
  cursor: pointer;

  @media only screen and (max-width: $screen-small) {
    display: grid;
    grid-template-columns: 3px min-content max-content 3px;
    grid-template-rows: initial;
    column-gap: 0rem;
    align-items: center;
    margin-left: 1rem;
    // padding: 0 1rem;
    width: 100%;
  }

  &::before {
    content: "";
    cursor: pointer;
    transform: translateX(-1.5rem);
    grid-column: 1/-1;
    grid-row: 1/2;
    width: 3px;
    height: 100%;
    transform: scaleY(0);
    transition: transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s;
    background-color: 0.2s;
    @media only screen and (max-width: $screen-small) {
      transform: translateX(0);
      transform: scaleY(0);
      grid-column: 1/-1;
      grid-row: 1/2;
      width: 100%;
      transition: transform 0.2s, background-color, 0.2s;
    }
  }

  &:hover::before {
    content: "";
    transform: scaleY(1);
    width: 100%;
    background-color: $color-primary-dark;

    @media only screen and (max-width: $screen-small) {
      width: initial;
      transform: scaleY(1);
    }
  }
}
.Active {
  transform: scaleY(1);
  background-color: $color-primary-dark;

  @media only screen and (max-width: $screen-small) {
    transform: scaleY(1);
    // width: 15rem;
    height: 100%;
  }
}

.MenuContainer {
  cursor: pointer;
  width: 4.5rem;
  height: 4rem;

  @media only screen and (max-width: $screen-small) {
    display: none;
  }
}
.Menu,
.Menu::before,
.Menu::after {
  display: block;
  width: 2.8rem;
  height: 0.5rem;
  position: absolute;
  background-color: $color-tertiary;
  border-radius: 2px;
  cursor: pointer;
}

.Menu {
  top: 1.8rem;
  left: 1rem;

  &::before {
    content: "";
    top: -1rem;
  }
  &::after {
    content: "";
    top: 1rem;
  }
}
