@import '../../variables';

.Services {
    margin-top: 2rem;
    display: grid;
    grid-template-rows: min-content min-content;
    justify-items: center;
}


.Heading3 {
    margin-bottom: 5rem;
    background-clip: text;
    background-image: linear-gradient(to bottom right, $color-primary-light, $color-primary-dark);
    color: transparent;
    font-weight: 400;
    font-family: $font-primary;
}

.CardsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    width: 90%;
    column-gap: 2rem;
    justify-self: center;
    row-gap: 2rem;

}
